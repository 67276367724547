import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';

import { PortableText } from '@portabletext/react';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import portableTextComponents from 'components/widgets/portableComponents';
import styled from '@emotion/styled';
import theme from 'theme/index';
import useMediaQuery from '@mui/material/useMediaQuery';

interface RoomButtonProps {
  title: string;
  text: any;
  link: string;
}

const RoomButtonContainer = styled.div`
  ${theme.breakpoints.down('md')} {
    flex-direction: row;
    width: fit-content;
  }
  display: flex;
  width: 50px;
  gap: ${theme.spacing(1)};
  flex-direction: column;
  align-items: center;
  overflow: visible;
  z-index: 1;
`;

const Circle = styled.div`
  ${theme.breakpoints.down('lg')} {
    width: 30px;
    height: 30px;
    :active {
      scale: 1.2;
    }
  }
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #FFFFFF;
  position: relative;
  z-index: 1;
`;

const LinkText = styled(Typography)`
  ${theme.breakpoints.down('lg')} {
    font-size: 1.125rem;
    :active {
      scale: 1.2;
    }
  }
  font-size: 1.5rem;
  color: #FFFFFF;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  z-index: 1;
`;

const HoverContainer = styled.div<{
  $display,
}>(({
  $display,
}) => `
  position: absolute;
  width: 260px;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: ${theme.shape.borderRadius};
  box-shadow: 1px 2px 3px #00000040;
  display: ${$display ? 'flex' : 'none'};
  padding: ${theme.spacing(2)};
  left: -40px;
  z-index: 15;
`);

const HoverText = styled.div`
  margin: ${theme.spacing(1, 0)};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: ${theme.spacing(1, 0)};
`;

const RoomButton = ({
  title,
  text,
  link,
}: RoomButtonProps) => {
  const [display, setDisplay,] = useState<boolean>(false);

  const {
    t,
  } = useTranslation();
  const router = useRouter();

  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const handleHoverOpen = () => {
    setDisplay(true);
  };

  const handleHoverClose = () => {
    setDisplay(false);
  };

  const handleLink = useCallback(() => {
    router.push(`/${link}`);
  }, [link, router,]);

  return <>
    <RoomButtonContainer
      onMouseEnter={isTablet ? undefined : handleHoverOpen}
      onClick={isTablet ? handleLink : undefined}
    >
      <Circle /* onClick={isMobile ? undefined : handleHoverOpen} */ />
      <LinkText>
        {title}
      </LinkText>

      <Grow in={display}>
        <HoverContainer $display={display}
          onMouseLeave={handleHoverClose}>
          <Grid container
            display={isTablet ? 'flex' : 'none'}
            justifyContent={'flex-end'}
            width={'100%'}>
            <IconButton /* onClick={handleHoverClose}*/>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Divider />
          <HoverText>
            <PortableText
              value={text}
              components={portableTextComponents}
            />
          </HoverText>
          <ButtonContainer>
            <Button variant={'contained'} onClick={handleLink}>
              {t('Move')}
            </Button>
          </ButtonContainer>
          <Divider />
        </HoverContainer>
      </Grow>

    </RoomButtonContainer>

  </>;
};
export default RoomButton;
