import { PageTitle } from 'components/styling/GeneralStyledComponents';
import { PortableText } from '@portabletext/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import AudioContext from 'utils/hooks/useAudio';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ContentContainer from 'components/layout/ContentContainer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import RoomButton from './components/RoomButton';
import Typography from '@mui/material/Typography';
import portableTextComponents from 'components/widgets/portableComponents';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const COLLAPSE_ANIMATION_DURATION = 250;

const IntroductionContainer = styled.div`
  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(5)};
    gap: ${theme.spacing(4)};
  }
  position: relative;
  width: 100%;
  margin-bottom: 100px;
`;

const ImageContainer = styled.div`
  ${theme.breakpoints.down('xl')} {
    left: 0px;
  }
  ${theme.breakpoints.down('lg')} {
    top: 80px;
    left: 20px;
    margin-bottom: 220px;
  }
  ${theme.breakpoints.down('md')} {
    top: 0px;
    left: 0px;
    width: 80%;
    margin-bottom: 0px;
  }
  position: relative;
  left: -80px;
`;

const Image = styled.img`
  ${theme.breakpoints.down('lg')} {
    width: 600px;
    height: 100%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 100%;
  }
  width: 1000px;
  height: 100%
  object-cover: cover;
`;

const ImageSources = styled.div`
  ${theme.breakpoints.down('lg')} {
    left: 0px;
    bottom: 30px;
  }
  ${theme.breakpoints.down('md')} {
    width: 45%;
    left: 50%;
    bottom: 10%;
  }
  ${theme.breakpoints.down('sm')} {
    bottom: -20px;
  }
  position: absolute;
  width: 240px;
  left: 460px;
  bottom: 60px;
  opacity: 0.4;
`;

const HouseImageSources = styled.div`
  ${theme.breakpoints.down('lg')} {
    left: 0px;
    bottom: 80px;
  }
  ${theme.breakpoints.down('md')} {
    width: 45%;
    left: 2%;
    bottom: 10%;
  }
  ${theme.breakpoints.down('sm')} {
    bottom: -20px;
  }
  position: absolute;
  width: 240px;
  left: 20px;
  bottom: 180px;
  opacity: 0.4;
`;

const SourceText = styled(Typography)`
  ${theme.breakpoints.down('md')} {
    font-size: 0.4rem;
  }
  font-size: 0.5rem;
  letter-spacing: 0px;
  line-height: 0.6rem;
`;

const TextContainer = styled.div`
  ${theme.breakpoints.down('lg')} {
    right: 0;
  }
  ${theme.breakpoints.down('md')} {
    position: relative;
    top: 0;
    right: 0;
    gap: ${theme.spacing(1)};
  }
  position: absolute;
  display: flex;
  flex-direction: column; 
  gap: ${theme.spacing(5)};
  align-items: center;
  top: 100px;
  right: -40px;
`;

const IntroductionText = styled(Typography)`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 0 ${theme.spacing(2)};
    text-align: center;
  }
  font-family: Yu Gothic;
  font-size: 1.125rem;
  width: 344px;
`;

const RoomContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  background-color: ${theme.palette.secondary.contrastText};
  padding: ${theme.spacing(4)};
  margin: auto;
`;

const RoomImage = styled.img`
  ${theme.breakpoints.down('md')} {
    height: 440px;
  }
  object-fit: cover;
  width: 100%;
  max-width: 1136px;
`;

const RoomButtonContainer = styled.div<{
  $top,
  $left,
}>(({
  $top,
  $left,
}) => `
  ${theme.breakpoints.down('md')} {
    position: relative;
    top: 0;
    left: 0;
  }
  position: absolute;
  top: ${$top};
  left: ${$left};
  z-index: 1;
`);

const FrontPage = ({
  doc,
}) => {
  const audioSrc = '/audio/frontpage.mp3';
  const [open, setOpen,] = useState(false);
  const [audio,] = useState(typeof Audio !== 'undefined' && new Audio(audioSrc));

  const {
    t,
  } = useTranslation();

  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    audioMainControlOn,
  } = useContext(AudioContext);

  const ref = useRef(null);

  const executeScroll = () => setTimeout(() => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, COLLAPSE_ANIMATION_DURATION);

  const handleOpen = () => {
    if (open === false) {
      executeScroll();
    }
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (audioMainControlOn) {
      audio.play();
      audio.loop = true;
    } else if (!audioMainControlOn) {
      audio.pause();
    }
    return () => {
      audio.pause();
    };
  }, [audio, audioMainControlOn,]);

  return <>
    <ContentContainer>
      <IntroductionContainer>
        <ImageContainer>
          <Image src="/images/sprengtporten.jpg" alt={t('Front page alt text')} />
          <HouseImageSources>
            <SourceText>
              {t('Source')}: Riukulehto, S., Rantanen, M. & Luostarinen, M.  (toim.) 2017. Ristiinan Brahelinna eilen, tänään,
              huomenna. Helsingin yliopisto Ruralia-instituutti. Raportteja 167. Kuvan tekijä Maria Luostarinen.
            </SourceText>
          </HouseImageSources>
          <ImageSources>
            <SourceText>
              {t('Artist')}: Lous Sparre (1863 - 1964)
            </SourceText>
            <SourceText>
              {t('Source')}: Wikimedia Commons. J. R. Danielson (1897):Finska kriget och Finlands krigare 1808 - 1809. s.28. Museovirasto (Suomi)
            </SourceText>
          </ImageSources>
        </ImageContainer>

        <TextContainer>
          <PageTitle>
            {t('Virtual museum')}
          </PageTitle>
          <IntroductionText>
            <PortableText
              value={doc?.introductionText}
              components={portableTextComponents}
            />

          </IntroductionText>
          {
            open ?
              <Button variant='text' onClick={handleOpen}>
                {t('Close')}
              </Button> :
              <Button variant='text' onClick={handleOpen} >
                {t('Come in')}!
              </Button>
          }

        </TextContainer>
      </IntroductionContainer>

      <Divider ref={ref} />

      {/* Room */}
      <Collapse in={open} timeout={COLLAPSE_ANIMATION_DURATION}>
        <RoomContainer>
          <RoomImage src="images/huone_kontrastilla.jpg" alt={t('Front page Room image alt text')} />

          {/* Roombuttons */}
          <Grid container
            flexDirection={'column'}
            gap={3}
            position={'absolute'}
            top={isMobile ? '60px' : '0px'} left={isMobile ? '60px' : '0px'}>
            <RoomButtonContainer
              $top={isTablet ? '260px' : '360px'}
              $left={isTablet ? '320px' : '460px'}>
              <RoomButton
                title={t('People')}
                text={doc.galleryText}
                link={'gallery'} />
            </RoomButtonContainer>
            <RoomButtonContainer
              $top={isTablet ? '340px' : '500px'}
              $left={isTablet ? '140px' : '180px'}>
              <RoomButton
                title={t('Maps')}
                text={doc.mapsText}
                link={'maps'} />
            </RoomButtonContainer>
            <RoomButtonContainer
              $top={isTablet ? '140px' : '260px'}
              $left={isTablet ? '680px' : '940px'}>
              <RoomButton
                title={t('Fun')}
                text={doc.funText}
                link={'fun'} />
            </RoomButtonContainer>
            <RoomButtonContainer
              $top={'240px'}
              $left={isTablet ? '140px' : '180px'}>
              <RoomButton
                title={t('Brahelinna')}
                text={doc.brahelinnaText}
                link={'brahelinna'} />
            </RoomButtonContainer>
            <RoomButtonContainer
              $top={isTablet ? '380px' : '500px'}
              $left={isTablet ? '590px' : '760px'}>
              <RoomButton
                title={t('Info')}
                text={doc.infoText}
                link={'info'} />
            </RoomButtonContainer>
            <RoomButtonContainer
              $top={isTablet ? '220px' : '280px'}
              $left={isTablet ? '440px' : '620px'}>
              <RoomButton
                title={t('Life')}
                text={doc.lifeText}
                link={'life'} />
            </RoomButtonContainer>
            <RoomButtonContainer
              $top={'120px'}
              $left={isTablet ? '480px' : '700px'}>
              <RoomButton
                title='Georg Magnus'
                text={doc.rolesText}
                link={'roles'} />
            </RoomButtonContainer>
          </Grid>
        </RoomContainer>
      </Collapse>

    </ContentContainer>
  </>;
};
export default FrontPage;
